import { useFetcher, useLocation, useMatches, useNavigate, useSearchParams } from "@remix-run/react";
import Fuse from "fuse.js";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { gtagEvent } from "~/utils/analytics/gtag";
import { useAppTenantRoute } from "~/utils/data/useRecipeRouteData";
import { useRootData } from "~/utils/data/useRootData";
import { useForkedRecipeSelectorContext } from "./forkedRecipesContext";
import { useIsBot } from "./isBotContext";

const SearchContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useSearchContextVals = () => {
  const [searchParams] = useSearchParams();
  const [shouldInitiate, setShouldInitiate] = React.useState(false);
  const [initiated, setInitiated] = useState(false);
  const [googleResults, setGoogleResults] = React.useState([]);
  const [captchaVisible, setCaptchaVisible] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") ?? "");
  const fetcher = useFetcher();
  const findRecipesFetcher = useFetcher();
  const asyncRecipeFetcher = useFetcher();
  const collectionFetcher = useFetcher();
  const [foundRecipes, setFoundRecipes] = useState([]);
  const [forkedResults, setForkedResults] = useState([]);
  const [collectionResults, setCollectionResults] = useState([]);
  const [brokenUrls, setBrokenUrls] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [allCollections, setAllCollections] = useState([]);
  const rootData = useRootData();
  const matches = useMatches();
  const location = useLocation();
  const locationRef = useRef();
  const navigate = useNavigate();
  locationRef.current = location.pathname;
  const isBot = useIsBot();
  const appTenantRoute = useAppTenantRoute();

  // create a function that will get the google results that are not in the found recipes
  const getUnfoundResults = useCallback(() => {
    const foundUrls = foundRecipes.map((r) => r.url);
    const unfoundResults = googleResults.filter((r) => {
      const url = new URL(r.url);
      const destinationUrl = url.searchParams.get("q");
      const isBroken = brokenUrls.some((brokenUrl) => brokenUrl === destinationUrl);
      return !foundUrls.includes(destinationUrl) && !isBroken;
    });
    // get an array of the unfound urls
    const unfoundUrls = unfoundResults.map((r) => {
      const url = new URL(r.url);
      return url.searchParams.get("q");
    });
    // console.log("unfoundUrls", unfoundUrls);
    const form = new FormData();
    form.set("urls", unfoundUrls.join(","));

    form.set("action", "get-recipes");
    asyncRecipeFetcher.submit(form, {
      method: "post",
      action: "/api/recipe/async",
    });
  }, [foundRecipes, googleResults, brokenUrls]);

  // const getAllCollections = () => {
  //   const form = new FormData();
  //   form.set("action", "get-collections");
  //   collectionFetcher.submit(form, {
  //     method: "post",
  //     action: "/api/recipe/async",
  //   });
  // };

  // useEffect(() => {
  //   if (initiated) {
  //     getAllCollections();
  //   }
  // }, [initiated]);

  // useEffect(() => {
  //   if (rootData.allCollections) {
  //     setAllCollections(rootData.allCollections);
  //   }
  // }, [rootData.allCollections]);

  // useEffect(() => {
  //   const getRootData = async () => {
  //     const allCollections = await rootData.allCollections;
  //     setAllCollections(allCollections);
  //   };

  //   if (rootData.allCollections) {
  //     getRootData();
  //   }
  // }, [rootData.allCollections]);

  const getAllUrls = () => {
    getUnfoundResults();
  };

  const setSearchParams = useCallback(
    (query: string) => {
      // if the query is empty, remove the search param
      let path = "/recipe/search";
      if (appTenantRoute) {
        path = `${appTenantRoute.pathname}/dashboard/search`;
      }
      const existingParams = new URLSearchParams(searchParams);
      const existingSearch = existingParams.get("q");
      if (existingSearch === query) return;
      if (query === "") {
        navigate({ pathname: path }, { replace: true });
        return;
      }
      const newsearchParams = new URLSearchParams();
      newsearchParams.set("q", query);
      const shouldReplace = locationRef.current.includes("search");
      console.log('navigating here')
      navigate({ pathname: path, search: "?" + newsearchParams.toString() }, { replace: shouldReplace });
    },
    [appTenantRoute, searchParams]
  );

  useEffect(() => {
    setSearchTerm(searchParams.get("q") ?? "");
  }, [searchParams]);

  const userRecipes = useForkedRecipeSelectorContext((state) => state.userRecipes);

  const addBrokenUrl = (url) => {
    setBrokenUrls((prev) => [...prev, url]);
  };

  const resultsReadyCallback = (name, q, promos, results, resultsDiv) => {
    console.log("resultsReadyCallback");
    setCaptchaVisible(false);
    // filter out any results that have a
    const filteredResults = results.filter((r) => {
      const title = r.titleNoFormatting;
      // find the titles that follow a format of starting with number followed by the word "recipes"
      const regex = new RegExp(/.*\d+.*recipes.*$/);
      // regex that will find text that has a number
      if (regex.test(title.toLowerCase())) {
        return false;
      }

      return true; // Keep all other strings
    });

    setGoogleResults(filteredResults);
    // saveSearch(q, results);
    return true; // Don't display normal search results.
  };

  const clearSearch = () => {
    setGoogleResults([]);
    setForkedResults([]);
    setSearchTerm("");
  };

  const checkForExising = useCallback(() => {
    const urls = googleResults
      .map((r) => {
        const url = new URL(r.url);
        return url.searchParams.get("q");
      })
      .join(",");
    if (urls.length === 0) return;
    const form = new FormData();
    form.set("urls", urls);
    form.set("action", "existing-recipes");
    findRecipesFetcher.submit(form, {
      method: "post",
      action: "/api/recipe/find",
    });
  }, [googleResults]);

  useEffect(() => {
    if (!googleResults.length) return;
    checkForExising();
    getUnfoundResults();
    // findRecipesFetcher.load(`/api/recipe/find?${searchParams.toString()}`);
  }, [googleResults]);

  useEffect(() => {
    // if location.pathname includes /search
    if (location.pathname.includes("/search")) {
      checkForExising();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (findRecipesFetcher.data) {
      setFoundRecipes(findRecipesFetcher.data.results);
      setBrokenUrls(findRecipesFetcher.data.broken);
      // wait 1 second before getting the unfound results
    }
  }, [findRecipesFetcher.data]);

  const saveSearch = useCallback(
    (query, results) => {
      const form = new FormData();
      form.set("query", query);
      form.set("results", JSON.stringify(results));
      fetcher.submit(form, {
        method: "post",
        action: "/api/search/save",
      });
    },
    [searchTerm]
  );

  const searchStartingCallback = (gname, query) => {
    console.log("searchStartingCallback query:", query);
    gtagEvent("search", { search_term: query });
    const originalQuery = query;
    setSearchParams(query);
    let gQuery = query;
    setTimeout(checkForCaptcha, 500);
    let isUrl = true;
    try {
      new URL(gQuery);
    } catch (e) {
      isUrl = false;
    }

    if (!isUrl) {
      if (!gQuery.includes("recipe")) {
        gQuery = gQuery + " recipe";
      }
      // remove the word recipe, how many words are left
      // const words = gQuery.replace("recipe", "").trim().split(" ");
      // // if there are less than 3 words, add "the best" to the beginning
      // if (words.length < 3) {
      //   gQuery = `the best ${gQuery}`;
      // }
      gQuery = `${gQuery} -intitle:recipes`;
    }
    console.log("originalQuery: ", originalQuery);
    setSearchParams(originalQuery);

    // handleFuzzySearch2(gQuery.replace("recipe", "").trim());
    return gQuery;
  };

  const handleInitiate = useCallback(() => {
    if (!initiated && !isBot) {
      const script = document.createElement("script");
      script.defer = true;
      document.head.append(script);
      script.src = "https://cse.google.com/cse.js?cx=f461c820daab74b1c";
      script.async = true;
      window.__gcse || (window.__gcse = {});
      window.__gcse.searchCallbacks = {
        web: {
          ready: resultsReadyCallback,
          starting: searchStartingCallback,
          // rendered: resultsRenderedCallback,
        },
      };
      setInitiated(true);
      return () => {
        script.remove();
      };
    }
  }, [initiated, searchStartingCallback, isBot]);

  // useEffect(() => {
  //   return handleInitiate();
  // }, []);

  const checkForCaptcha = () => {
    const captchaVisible = document.getElementById("recaptcha-element");
    if (captchaVisible) {
      setCaptchaVisible(true);
    }
  };

  const userRecipesStringified = useMemo(() => {
    return JSON.stringify(userRecipes);
  }, [userRecipes]);

  const userCollectionsStringified = useMemo(() => {
    return JSON.stringify(allCollections);
  }, [allCollections]);

  const fuse = useMemo(() => {
    return new Fuse(userRecipes, {
      keys: ["name", "url"],
      includeScore: true,
      threshold: 0.3,
    });
  }, [rootData.userSession?.userId, userRecipesStringified]);

  const collectionFuse = useMemo(() => {
    return new Fuse(allCollections, {
      keys: ["name", "description"],
      includeScore: true,
      threshold: 0.3,
    });
  }, [rootData.userSession?.userId, userCollectionsStringified]);

  useEffect(() => {
    if (searchTerm !== "") {
      handleFuzzySearch2(searchTerm);
    } else {
      setForkedResults(userRecipes);
    }
  }, [userRecipesStringified]);

  useEffect(() => {
    if (searchTerm !== "") {
      handleCollectionFuzzySearch(searchTerm);
    } else {
      setCollectionResults([]);
    }
  }, [userCollectionsStringified]);

  useEffect(() => {
    if (searchTerm !== "") {
      handleFuzzySearch2(searchTerm);
      handleCollectionFuzzySearch(searchTerm);
    } else {
      setForkedResults(userRecipes);
      setCollectionResults([]);
    }
  }, [searchTerm]);

  const handleFuzzySearch2 = useCallback(
    (searchTerm: string) => {
      const result = fuse.search(searchTerm);
      const results = result.map((r) => {
        return r.item;
      });
      setForkedResults(results);
    },
    [fuse]
  );

  const handleCollectionFuzzySearch = useCallback(
    (searchTerm: string) => {
      const result = collectionFuse.search(searchTerm);
      const results = result.map((r) => {
        return r.item;
      });
      setCollectionResults(results);
    },
    [collectionFuse]
  );

  return {
    googleResults,
    setGoogleResults,
    setShouldInitiate,
    captchaVisible,
    searchTerm,
    setSearchTerm,
    foundRecipes,
    forkedResults,
    collectionResults,
    clearSearch,
    addBrokenUrl,
    brokenUrls,
    initiated,
    getAllUrls,
    handleInitiate,
    searchActive,
    setSearchActive,
    setSearchParams,
  };
};

const SearchContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const googlesearchContext = useSearchContextVals();

  return <SearchContext.Provider value={{ ...googlesearchContext }}>{props.children}</SearchContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useSearchContext = () => useContext(SearchContext);
const useSearchSelectorContext = (selector: any) => {
  return useContextSelector(SearchContext, selector);
};
export { SearchContextProvider, useSearchContext, useSearchSelectorContext };
